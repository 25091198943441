<template>
  <div>
    <!-- for future design sake keep it seperate! -->
    <!-- <associatedOption
      :fieldAttributes="{ name: 'Connection',associatedOption: connectionOption }"
      type="combobox"
      :fieldAttrInput="{}"
      :value="connectionValue"
      @input="updateInput('connectionId',...arguments)"
    ></associatedOption>
    <associatedOption
      :fieldAttributes="{ name: 'Template',associatedOption: templateOption }"
      type="combobox"
      :fieldAttrInput="{}"
      :value="templateValue"
      @input="updateInput('templateId',...arguments)"
    ></associatedOption>-->
    <fieldsRows
      :templateContent="templateContent"
      @field-value-changed="updateFieldValue(...arguments)"
      v-on="$listeners"
      :valueLine="{
        connectionId: this.connectionValue,
        templateId: this.templateValue,
      }"
    ></fieldsRows>
  </div>
</template>
<script>
import fieldsRows from "@/commonComponents/fieldsRows.vue";
export default {
  components: {
    fieldsRows,
  },
  props: {
    connectionOption: {},
    templateOption: {},
    connectionValue: { type: [String, Number] },
    templateValue: { type: [String, Number] },
    addUrlAttr: { type: Object },
    dialogRef: { type: String },
    result: {},
    stepStoredValue: {},
  },
  methods: {
    updateFieldValue(field, value) {
      console.log(value);
      this.$emit("value-changed", field, value);
    },
    emitUpdateFields(info) {
      console.log("------>", info);
      this.$emit("updated-fields", info);
    },
  },
  computed: {
    templateContent() {
      let templateContent = {
        fields: {
          connectionId: {
            type: "combobox",
            fieldAttrInput: { class: "required" },
            value: this.connectionValue,
            associatedOption: this.connectionOption,
            addingURL:
              "serve.php?f=configuration&f2=outboundConnections&f3=nocsConnection",
            addingFunction: "addConnection",
          },
          templateId: {
            type: "combobox",
            value: this.templateValue,
            associatedOption: this.templateOption,
            fieldAttrInput: { class: "required" },
            addingURL:
              "serve.php?f=configuration&f2=nocsPacketTemplates" +
              "&p=" +
              this.addUrlAttr.category +
              "&c_subType=" +
              this.addUrlAttr.subType +
              "&c_type=" +
              this.addUrlAttr.stepType,
          },
        },
        stepStoredValue: this.stepStoredValue,
        subType: this.addUrlAttr.subType,
        category: this.addUrlAttr.category,
        stepType: this.addUrlAttr.stepType,
        dialogRef: this.dialogRef,
        enableAdd: true,
        //contentID: this.addUrlAttr.stepType + "_" + this.addUrlAttr.stepID
        contentID: this.addUrlAttr.stepType
      };
      if (this.connectionOption === false) {
        this.$delete(templateContent.fields, "connectionId");
      }
      templateContent.fields = this.languageHelpProvider(
        templateContent.fields,
        "connectionBlock"
      );
      templateContent.disableAllInputs = this.result.disableAllInputs;
      return templateContent;
    },
  },
};
</script>
