<template>
  <div>
    <buildFieldsRows
      :templateContent="templateContent"
      v-model="localValue"
      languageIndex="errorActionFields"
    ></buildFieldsRows>
  </div>
</template>
<script>
import options from "@/cfg/options.json";
import buildFieldsRows from "@/commonComponents/buildFieldsRows.vue";
export default {
  components: { buildFieldsRows },
  data() {
    return {};
  },
  props: {
    value: Object,
    checkReadOnly: { default: false },
    result: { type: Object },
    source: { type: String,default: "" },
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(newVal) {
        this.$emit("input", newVal);
      },
    },
    templateContent() {
      let templateContent = {
        fields: {
          errorActionLine: {
            type: "line",
            fields: {
              else: {
                type: "string",
                string: typeof this.source!='undefined' && this.source=='IO_step'?"":"else",
                colAttrs: { style: "max-width:28px", class: "ml-n2" },
              },
              result: {
                type: "select",
                associatedOption: options.ldapResult,
                //value: this.value.result,
                fieldAttrInput: {
                  class: "required",
                  readonly: this.checkReadOnly,
                },
                default: "error",
              },
              action: {
                type: "select",
                associatedOption: options.evalAction,
                // value: this.value.action,

                fieldAttrInput: {
                  class: "required",
                  readonly: this.checkReadOnly,
                },
                default: "stopTest",
              },
              info: {
                type: "text",
                name: "Info",
                fieldAttrInput: { class: "" ,maxlength:'100' },
              },
            },
          },
        },
        disableAllInputs: this.result.disableAllInputs,
      };

      return templateContent;
    },
  },
};

// function getErrorActionFields(value) {
//   // let evalAction = $fieldsObject.data("evalAction");
//   let evalAction = window.evalAction;
//   return "";
// }
</script>