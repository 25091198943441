<template>
  <div>
    <div v-if="value != null">
      <SSH_commands
        v-for="(templateCommand, key) in value"
        :key="key"
        :command="templateCommand"
        @command-value-changed="processCommandData(key, ...arguments)"
        :stepType="'SSH'"
        :groupedStepDependentInfo="{}"
        addButtTitle="Add Evaluation"
        :readOnly="readOnly"
        @delete-command="deleteCommand(key)"
        :result="result"
        :isValid="isValid"
        :linePrefix="idPrefix + '_' + key"
        >/* delete-command: get the event from child component: ssh_commands,
        manipulate it here in deleteCommand function */
      </SSH_commands>
    </div>
    <!--
                <v-divider class="pb-3"></v-divider>
    -->
    <primaryButton
      v-if="!readOnly"
      label="Add Command"
      icon="mdi-plus"
      @click="addCommand()"
      type="button"
      color="primary"
      id="SSH_add_command_butt"
      :disabled="disabled || !isValid"
    ></primaryButton>
  </div>
</template>
<script>
import primaryButton from "@/commonComponents/primaryButton.vue";
import SSH_commands from "@/components/legacy/SSH_commands.vue";

export default {
  components: {
    SSH_commands,
    primaryButton,
  },
  props: ["value", "readOnly", "result", "isValid", "idPrefix"],
  computed: {
    disabled() {
      let disabled = false;
      if (
        typeof this.result != "undefined" &&
        typeof this.result.disableAllInputs != "undefined" &&
        this.result.disableAllInputs == true
      ) {
        disabled = true;
      }
      return disabled;
    },
  },
  methods: {
    deleteCommand(key) {
      console.log(this.value);
      let localArray = [...this.value];
      localArray.splice(key, 1);
      /* update the input*/
      this.$emit("input", localArray);
    },
    addCommand() {
      // if (
      //   this.templateStoreValue == null ||
      //   typeof this.templateStoreValue == "undefined" ||
      //   typeof this.templateStoreValue.commands == "undefined"
      // ) {
      //   this.templateStoreValue = { commands: {} };
      // }
      // console.log("value changed ", this.templateStoreValue);
      // this.$set(this.templateStoreValue.commands, key, value);
      // this.$emit("stored-value-changed", this.templateStoreValue);
      let localValue = [];
      if (typeof this.value != "undefined") {
        localValue = [...this.value];
      }
      localValue.push({ res: { eval: {} } });
      this.$emit("input", localValue);
    },

    processCommandData(key, value) {
      let localValue = [...this.value];

      this.$set(localValue, key, value);

      this.$emit("input", localValue);
    },
  },
};
</script>