<template>
  <div :class="divcolor + ' pa-2'">
    <!-- <div class="pb-5"></div>-->
    <lineFieldsContainer
      :fieldAttrRow="{}"
      :fields="commandFields"
      :valueLine="valueLine"
      @field-value-changed="checkCommandInput(...arguments)"
      @click="checkClicked(...arguments)"
      @mouseenter="checkMouseenter(...arguments)"
      @mouseleave="checkmouseleave(...arguments)"
      :templateContent="result"
      :linePrefix="linePrefix"
    ></lineFieldsContainer>
    <v-card
      v-if="valueLine.commandType != 'multiCommand'"
      class="mx-auto"
      flat
      outlined
      width="90%"
    >
      <v-toolbar color="rgba(146, 169, 196, 0.26)" flat height="50">
        <v-toolbar-title class="subtitle-2 ml-5">{{
          language.componentLabel.labelOE
        }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <div class>
          <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="fields"
            :templateContent="result"
            :value="checkEvalValue"
            @line-field-input="checkEvalInput(...arguments)"
            :headerTitle="true"
            :addable="true"
            :addButtTitle="addButtTitle"
            :isValid="isValid"
            :linePrefix="linePrefix + '_addEval'"
          ></lineFieldsArray>
        </div>
      </v-container>
    </v-card>
    <v-card
      v-if="typeof this.localCommand.expect != 'undefined'"
      class="mx-auto"
      flat
      outlined
      width="90%"
    >
      <v-toolbar color="rgba(146, 169, 196, 0.26)" flat height="50">
        <v-toolbar-title class="subtitle-2 ml-5">{{
          language.componentLabel.labelIOSteps
        }}</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <div class>
          <lineFieldsArray
            :fieldAttrRow="{}"
            :fields="inputOutputFields"
            :templateContent="result"
            :value="expectFlow"
            @line-field-input="updateExpect(...arguments)"
            :headerTitle="false"
            :addable="!readOnly"
            addButtTitle="Add I/O Step"
            :isValid="isValid"
            :linePrefix="linePrefix + '_addIOStep'"
          ></lineFieldsArray>
          <v-card class="mt-2">
            <v-card-text>
              <div class="subtitle-1 ma-3">
                {{ language.componentLabel.labelOnError }}
              </div>
              <errorActionFields
                v-model="onErrorValue"
                :checkReadOnly="readOnly"
                :result="result"
                source="IO_step"
              ></errorActionFields>
            </v-card-text>
          </v-card>
        </div>
      </v-container>
    </v-card>
    <div class="pt-5"></div>
  </div>
</template>
<script>
import lineFieldsArray from "@/commonComponents/lineFieldsArray.vue";
import lineFieldsContainer from "@/commonComponents/lineFieldsContainer.vue";
import options from "@/cfg/options.json";
import lineButtons from "@/cfg/lineButtons.json";
import errorActionFields from "@/components/legacy/errorActionFields.vue";

export default {
  components: {
    lineFieldsArray,
    lineFieldsContainer,
    errorActionFields,
  },
  data: function () {
    return { divcolor: "" };
  },
  props: {
    command: {},
    stepType: { type: String },
    groupedStepDependentInfo: { type: Object },

    addButtTitle: { type: String, default: "Add Eval" },
    addCommandButtTitle: { type: String, default: "Add Command" },
    readOnly: Boolean,
    result: { type: Object },
    isValid: { type: Boolean },
    linePrefix: { type: String, default: "" },
  },
  computed: {
    checkEvalValue() {
      console.log("--->", this.localCommand);
      let output = [];
      //let output = [{action: "",content: "",else: "",operator: "",remove: "",result: ""}];
      if (
        typeof this.localCommand.res.eval != "undefined" &&
        typeof this.localCommand.res.eval.output != "undefined"
      ) {
        output = this.localCommand.res.eval.output;
      } else {
        this.$set(this.localCommand.res, "eval", { output: [] });
      }
      console.log(output);
      return output;
    },
    onErrorValue: {
      get() {
        let onErrorValue = { result: "error", action: "stopTest" };
        if (
          typeof this.localCommand != "undefined" &&
          typeof this.localCommand.expect != "undefined" &&
          typeof this.localCommand.expect.OnError != "undefined"
        ) {
          onErrorValue = this.localCommand.expect.OnError;
        }
        // this.$set(onErrorValue, "readOnly", this.readOnly);
        return onErrorValue;
      },
      set(newVal) {
        this.$set(this.localCommand.expect, "OnError", newVal);
        this.$emit("command-value-changed", this.localCommand);
      },
    },
    onErrorContent() {
      let content = {
        fields: {
          linecontainer: {
            type: "line",
            fields: {
              result: {
                type: "select",
                name: "Result",
                options: options.ldapResult,
                // colAttrs: { cols: 1 },
                default: "error",
              },
              action: {
                type: "select",
                name: "Action",
                options: options.evalAction,
                default: "stopTest",
              },
            },
          },
        },
      };
      return content;
    },
    valueLine() {
      let commandType;
      if (typeof this.localCommand.command != "undefined") {
        commandType = "command";
      } else if (typeof this.localCommand.expect != "undefined") {
        commandType = "multiCommand";
      } else if (typeof this.localCommand.getfile != "undefined") {
        commandType = "getfile";
      } else if (typeof this.localCommand.spawn != "undefined") {
        commandType = "spawn";
      }
      let format = "";
      if (
        typeof this.localCommand.res != "undefined" &&
        typeof this.localCommand.res.format != "undefined"
      ) {
        format = this.localCommand.res.format;
      }
      let command;
      if (commandType == "multiCommand") {
        command = this.localCommand.expect.command;
      } else {
        command = this.localCommand[commandType];
      }
      let result = {
        commandType: commandType, //get next obj
        format: format,
        command: command,
        readOnly: this.readOnly,
      };
      return result;
    },
    localCommand: {
      get() {
        return this.command;
      },
      set(newVal) {
        console.log("localCooman", newVal);
        console.log(" local command setter called", newVal);
        this.$emit("command-value-changed", newVal);
      },
    },
    fields() {
      let fields = {
        operator: {
          type: "select",
          name: "Operator",
          associatedOption: [
            "contains",
            "does not contains",
            "save in variable",
          ],
          fieldAttrInput: { class: "required" },
        },
        content: {
          type: "text",
          name: "Content",
          inputFieldFormatter: "evalContentFormatter",
          fieldAttrInput: { class: "required" },
        },
        else: {
          type: "string",
          string: "else",
          fieldAttrInput: { class: "mt-3 ml-1" },
          colAttrs: { style: "max-width:33px" },
        },
        result: {
          type: "select",
          name: "Result",
          associatedOption: options.ldapResult,
          fieldAttrInput: { class: "required" },
          default: "error",
        },
        action: {
          type: "select",
          name: "Action",
          associatedOption: options.evalAction,
          fieldAttrInput: { class: "required" },
          default: "stopTest",
        },
        info: {
          type: "text",
          name: "Info",
          fieldAttrInput: { class: "" ,maxlength:'100' },
        },
        remove: {
          inputFieldFormatter: "trash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-1",
          },
        },
      };
      return fields;
    },
    inputOutputFields() {
      let fields = {
        cmd: {
          type: "select",
          name: "Type",
          associatedOption: { send: "Send", expect: "Expect" },
          colAttrs: { cols: 5 },
          fieldAttrInput: { class: "required" },
          default: "send",
        },
        content: {
          type: "text",
          name: "Content",
          inputFieldFormatter: "sshExpectInputFormatter",
          colAttrs: {},
          fieldAttrInput: { class: "required" },
        },

        timeout: {
          type: "number",
          name: "Timeout",
          inputFieldFormatter: "sshExpectTimeoutFormatter",
          colAttrs: { cols: 2 },
          // default: 30, it will not work here because it is not there on the first sleection. it is set when cmd type chagned
          fieldAttrInput: { class: "required" },
        },
        remove: lineButtons.remove,
      };
      return fields;
    },
    commandFields() {
      let fields = {
        commandType: {
          type: "select",
          name: "Type",
          associatedOption: options.sshMode,
          fieldAttrInput: { class: "required" },
        },
        command: {
          type: "textarea",
          name: "Command",
          fieldAttrInput: { class: "required", rows: 1 },
        },

        format: {
          type: "select",
          name: "Expected output format ",
          associatedOption: options.sshFormat,
          inputFieldFormatter: "sshFormatInputFormatter",
          colAttrs: {},
          fieldAttrInput: { class: "required" },
        },
        remove: {
          inputFieldFormatter: "trash",
          colAttrs: {
            style: "max-width: 23px;min-width: 35px;",
            class: "mt-1",
          },
        },
      };
      // if (typeof this.localCommand.expect != "undefined") {
      //   this.$delete(fields, "format");
      // }
      return fields;
    },
    expectFlow() {
      let expectFlow = [];
      if (typeof this.localCommand.expect != "undefined") {
        let stored = this.localCommand.expect.expectFlow;

        stored.forEach((element) => {
          let line = { ...element };

          this.$set(line, "content", line[line.cmd]);
          this.$set(line, "readOnly", this.readOnly);
          expectFlow.push(line);
        });
      }
      return expectFlow;
    },
  },
  methods: {
    checkMouseenter(name) {
      if (name == "trash") {
        this.divcolor = "red lighten-5";
      }
    },
    checkmouseleave(name) {
      if (name == "trash") {
        this.divcolor = "";
      }
    },
    checkClicked(name) {
      if (name == "trash") {
        this.$emit("delete-command");
      }
    },
    checkEvalInput(value) {
      let localCommand = { ...this.command };
      this.$set(localCommand.res.eval, "output", value);
      this.$emit("command-value-changed", localCommand);
    },
    updateExpect(stored) {
      //console.log(stored);
      let localCommand = { ...this.command };
      let expectFlow = [];
      stored.forEach((element) => {
        let line = { ...element };

        this.$set(line, line.cmd, line.content);
        this.$delete(line, "content");
        if (line.cmd != "expect") {
          this.$delete(line, "timeout");
        } else {
          if (typeof line.timeout == "undefined") {
            line.timeout = 30;
          }
        }
        expectFlow.push(line);
      });
      this.$set(localCommand.expect, "expectFlow", expectFlow);
      this.$emit("command-value-changed", localCommand);
    },
    checkCommandInput(field, value) {
      // console.log("----Command--------", this.valueLine);
      // console.log(field, value);
      // let localCommand = { ...this.command };
      // console.log(this.localCommand);

      if (field == "format") {
        this.$set(this.localCommand.res, "format", value);
      }
      if (field == "commandType") {
        let commandType = this.valueLine.commandType;
        if (commandType == "multiCommand") {
          commandType = "command";
        }

        if (value == "multiCommand") {
          if (typeof this.localCommand.expect == "undefined") {
            this.$set(this.localCommand, "expect", {
              expectFlow: [],
              OnError: { result: "error", action: "stopTest" },
            });
            this.$set(
              this.localCommand.expect,
              commandType,
              this.valueLine.command
            );
          }
          this.$delete(this.localCommand, "res");
        } else {
          if (typeof this.localCommand.res == "undefined") {
            this.$set(this.localCommand, "res", { eval: {} });
          }
          this.$delete(this.localCommand, "expect");
        }
        let commandValue;
        if (typeof this.localCommand.command != "undefined") {
          commandValue = this.localCommand.command;
          this.$delete(this.localCommand, "command");
        } else if (typeof this.localCommand.getfile != "undefined") {
          commandValue = this.localCommand.getfile;
          this.$delete(this.localCommand, "getfile");
        } else if (typeof this.localCommand.spawn != "undefined") {
          commandValue = this.localCommand.spawn;
          this.$delete(this.localCommand, "spawn");
        }
        if (typeof commandValue == "undefined") {
          commandValue = "";
          // commandValue = this.localCommand.command;
        }
        //this.$set(this.localCommand, value, commandValue);
        this.updateCommand(value, commandValue);
      }
      if (field == "command") {
        let commandType = this.valueLine.commandType;
        this.updateCommand(commandType, value);
      }
      //console.log(JSON.stringify(this.localCommand));
      this.$emit("command-value-changed", this.localCommand);
    },
    updateCommand(commandType, value) {
      if (commandType == "multiCommand") {
        commandType = "command";
        this.$set(this.localCommand.expect, commandType, value);
      } else {
        this.$set(this.localCommand, commandType, value);
      }
    },
  },
};
</script>