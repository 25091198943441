<template>
  <div>
    <step_connectionTemplateBlock
      v-if="connectionTemplateInfo != null"
      :connectionOption="connectionTemplateInfo.connectionsArray"
      :templateOption="connectionTemplateInfo.templates"
      :connectionValue="stepStoredValue.connectionId"
      :templateValue="stepStoredValue.templateId"
      @value-changed="updateTemplate"
      :addUrlAttr="{
        subType: stepStoredValue.subType,
        category: stepStoredValue.category,
        stepType: stepType,
      }"
      :stepStoredValue="stepStoredValue"
      :dialogRef="result.dialogRef"
      @updated-fields="emitUpdateFields"
      :result="result"
    ></step_connectionTemplateBlock>
    <!--
        <v-divider></v-divider>
        -->
    <v-expansion-panels focusable>
      <v-expansion-panel>
        <v-expansion-panel-header color="primary" class="white--text"
          >{{ language.componentLabel.titleCommands }}
        </v-expansion-panel-header>
        <v-expansion-panel-content value="1">
          <div v-if="templateStoreValue != null">
            <!--{{templateStoreValue.commands}}-->
            <sshCommandsContainer
              :value="commands"
              :readOnly="true"
              @input="processCommandData"
              :result="result"
              :isValid="isValid"
              :idPrefix="stepType + '_' + stepID"
            ></sshCommandsContainer>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-divider></v-divider>
    <div class="pt-5"></div>
    <onTimeOutNoResult
      onEvent="On Timeout"
      v-model="onTimeout"
      :result="result"
    ></onTimeOutNoResult>
  </div>
</template>
<script>
import sshCommandsContainer from "@/components/legacy/sshCommandsContainer.vue";
import step_connectionTemplateBlock from "@/components/legacy/step_connectionTemplateBlock.vue";
import onTimeOutNoResult from "@/components/legacy/onTimeOutNoResult.vue";
import { isObjectArray } from "@/js/helper.js";

export default {
  components: {
    sshCommandsContainer,
    step_connectionTemplateBlock,
    onTimeOutNoResult,
  },
  computed: {
    commands() {
      let localCommands = [];
      if (typeof this.templateStoreValue.commands != "undefined") {
        localCommands = JSON.parse(
          JSON.stringify(this.templateStoreValue.commands)
        );
        // let localTemplate = JSON.parse(JSON.stringify(fromTemplate));
        localCommands.forEach((localTemplate, key) => {
          console.log(this.stepStoredValue.commands);
          let fromTestcase = {};
          if (
            typeof this.stepStoredValue.commands != "undefined" &&
            typeof this.stepStoredValue.commands[key] != "undefined"
          ) {
            fromTestcase = this.stepStoredValue.commands[key];
          }
          if (typeof localTemplate.res == "undefined") {
            localTemplate.res = {};
          }
          if (typeof localTemplate.res.eval == "undefined") {
            localTemplate.res.eval = {};
          }
          if (typeof fromTestcase.res == "undefined") {
            fromTestcase.res = {};
          }
          if (typeof fromTestcase.res.eval == "undefined") {
            fromTestcase.res.eval = {};
          }
          console.log(fromTestcase);
          if (fromTestcase.res && fromTestcase.res.eval) {
            let commandsMerged = this.mergeAttributeSSH(
              localTemplate.res.eval["output"],
              fromTestcase.res.eval["output"]
            );
            this.$set(localTemplate.res.eval, "output", commandsMerged);
          }
          console.log("--------From Merge----");
          console.log(localTemplate);
          this.$set(localCommands, key, localTemplate);
        });
      }
      return localCommands;
    },
    onTimeout: {
      get() {
        var onTimeout = {};
        if (
          typeof this.stepStoredValue != "undefined" &&
          typeof this.stepStoredValue["res"] != "undefined"
        ) {
          if (typeof this.stepStoredValue.res.onTimeout != "undefined") {
            onTimeout = this.stepStoredValue.res.onTimeout;
          }
        }
        return onTimeout;
      },
      set(newVal) {
        let localStoredStep = { ...this.stepStoredValue };

        if (localStoredStep == null || typeof localStoredStep == "undefined") {
          localStoredStep = {};
        }
        if (
          localStoredStep == null ||
          typeof localStoredStep == "undefined" ||
          typeof localStoredStep.res == "undefined"
        ) {
          this.$set(localStoredStep, "res", {});
        }
        this.$set(localStoredStep.res, "onTimeout", newVal);
        console.log("value changed ", localStoredStep);
        this.$emit("stored-value-changed", localStoredStep);
      },
    },
  },
  created() {
    this.templateDetails(this.stepStoredValue.templateId);
    this.sshClientServer();
  },
  data: function () {
    return {
      templateStoreValue: [],
      sshClientServerList: {},
      connectionTemplateInfo: null,
      attrValues: {},
    };
  },
  methods: {
    emitUpdateFields(info) {
      if (typeof info.templateId != "undefined") {
        this.$set(
          this.connectionTemplateInfo,
          "templates",
          info.templateId.associatedOption
        );
        //this.templateId = info.templateId.value;
        this.updateTemplate("templateId", info.templateId.value);
      } else if (typeof info.connectionId != "undefined") {
        this.$set(
          this.connectionTemplateInfo,
          "connectionsArray",
          info.connectionId.associatedOption
        );
        this.updateTemplate("connectionId", info.connectionId.value);
      } else {
        //stay home!
      }
      //console.log("<---updated-fields--<<",info);
    },
    insertObjectSSH(sourceArray, addValuesArray) {
      var returnArray = [];
      returnArray = JSON.parse(JSON.stringify(sourceArray));
      // add the values which are not found to the source array
      addValuesArray.forEach(function (singleValue) {
        var foundIndex = -1;
        var searchObject = {};
        //$.extend(searchArray, singleValue);
        searchObject = { ...singleValue };
        delete searchObject.fix;
        delete searchObject.source;
        sourceArray.forEach(function (resInnervalue, resInnerIndex) {
          var found = false;
          for (const [searchIndex, searchValue] of Object.entries(
            searchObject
          )) {
            if (
              typeof resInnervalue[searchIndex] != "undefined" &&
              resInnervalue[searchIndex] == searchValue
            ) {
              //same value move on
              found = true;
            } else {
              found = false;
              return false;
            }
          }
          if (found) {
            // put original
            foundIndex = resInnerIndex;
          }
        });
        if (foundIndex > -1) {
          //NO NEED FOR SSH Structure!
          // put original
          // returnArray[foundIndex]=singleValue;
        } else {
          returnArray.push(singleValue);
        }
      });
      return returnArray;
    },
    mergeAttributeSSH(target, source) {
      var finalObject = [];
      var copiedSource = [];
      if (typeof target != "undefined") {
        finalObject = JSON.parse(JSON.stringify(target));
      }
      if (typeof source != "undefined") {
        copiedSource = JSON.parse(JSON.stringify(source));
      }
      if (isObjectArray(finalObject)) {
        finalObject.forEach(function (attrValue, attrIndex) {
          finalObject[attrIndex].readOnly = true;
        });
      }
      if (isObjectArray(copiedSource)) {
        finalObject = this.insertObjectSSH(finalObject, copiedSource);
      }
      console.log(finalObject);
      return finalObject;
    },
    processCommandData(value) {
      let localStoredStep = { ...this.stepStoredValue };
      // if (
      //   localStoredStep == null ||
      //   typeof localStoredStep == "undefined" ||
      //   typeof localStoredStep.commands == "undefined"
      // ) {
      //   localStoredStep = { commands: {} };
      // }
      this.$set(localStoredStep, "commands", value);
      console.log("value changed ", localStoredStep);
      this.$emit("stored-value-changed", localStoredStep);
    },
    sshClientServer() {
      if (this.additionalStepsInfo[this.stepType]) {
        this.connectionTemplateInfo = this.additionalStepsInfo[this.stepType];
      } else {
        let options = {
          function: "getSSHClientServer",
          dataType: "json",
          requestType: "ajax",
        };
        let caller = this;
        this.frameworkAxiosRequest({
          method: 'post',
          url: 'serve.php?f=testing&f2=configureAndRunTests',
          data: options,
        })
            .then(function (response) {
            let connectionData = JSON.parse(response.request.response);
            caller.connectionTemplateInfo = connectionData.result.json;
            caller.$set(
              caller.result.additional.additionalStepsInfo,
              caller.stepType,
              caller.connectionTemplateInfo
            );
            caller.$store.commit("dialogResultChanged", caller.result);
          })
          .catch(function () {
            //handle error
          });
      }
    },
    templateDetails(value) {
      console.log(value);
      let options = {
        function: "getSSHTemplate",
        id: value,
        dataType: "json",
        requestType: "ajax",
      };
      let caller = this;
      this.frameworkAxiosRequest({
        method: 'post',
        url: 'serve.php?f=testing&f2=configureAndRunTests',
        data: options,
      })
          .then(function (response) {
          let templateInData = JSON.parse(response.request.response);
          caller.templateStoreValue = JSON.parse(templateInData.result.string);
          console.log("caller.templateStoreValue");
          console.log(caller.templateStoreValue);
        })
        .catch(function () {
          //handle error
        });
    },
    templateOption() {
      let result = {};
      if (
        typeof this.additionalStepsInfo[this.stepType]["templates"] !=
        "undefined"
      ) {
        result = this.additionalStepsInfo[this.stepType]["templates"];
      }
      return result;
    },
    updateTemplate(field, value) {
      console.log("update tempate called", value);
      let localStoredStep = { ...this.stepStoredValue };
      if (localStoredStep == null) {
        localStoredStep = {};
      }
      this.$set(localStoredStep, field, value);
      this.$emit("stored-value-changed", localStoredStep);
      if (field == "templateId") {
        this.templateDetails(value);
      }
    },
  },
  props: [
    "result",
    "stepStoredValue",
    "stepType",
    "groupedStepDependentInfo",
    "stepID",
    "additionalStepsInfo",
    "isValid",
  ],
};
</script>